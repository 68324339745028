/* global.css */

/* Reset some default browser styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Body styles */
  body {
    font-family: 'Inter', sans-serif;
    background-color: #f4f4f4;
    color: #333;
    line-height: 1.6;
  }
  
  /* Global header styles */
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 20px;
    color: #444;
  }
  
  /* Utility classes */
  .text-center {
    text-align: center;
  }
  
  .mt-20 {
    margin-top: 20px;
  }
  
  .page-width{
    width: 80%;
    margin: 0 auto;
  }